<template>
  <div class="technology">
    <div class="technology_banner">
      <img
        src="https://qxqy-online.sdhckjvip.com/official/banner/banner_42x.png"
        alt=""
      />
    </div>
    <div class="technology_container">
      <div class="box technology_container_content">
        <div ref="boxOneRef">软件开发与技术支持</div>
        <img
          :class="{ view_to_bottom: boxOneShow }"
          src="https://qxqy-online.sdhckjvip.com/OfficialWebsite/technologyimg.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "technology",
  data() {
    return {
      currentScroll: 0,
      boxOneShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handalScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handalScroll, true);
  },
  methods: {
    handalScroll() {
      this.currentScroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (this.currentScroll * 2 >= this.$refs.boxOneRef.offsetTop) {
        this.boxOneShow = true;
      }
    },
  },
};
</script>
<style scoped>
div {
  box-sizing: border-box;
}
.technology_banner {
  width: 100%;
  height: 700px;
}
.technology_banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.technology_container {
  width: 100%;
  /* height: 882px; */
  background-image: url("https://qxqy-online.sdhckjvip.com/official/cooperative_bg@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.technology_container_content {
  padding-top: 126px;
}
.technology_container_content > div:nth-child(1) {
  text-align: center;
  font-size: 40px;
  color: #292c33;
  font-weight: 600;
}
.technology_container_content > img {
  width: 100%;
  /* height: 508px; */
  margin-top: 60px;
  transform: translateY(100px);
  opacity: 0;
  cursor: pointer;
}
.view_to_bottom {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: all 0.5s ease;
}
@media (max-width: 1600px) {
  .technology_container_content > img{
    opacity: 1;
    transform: translateY(0px);
  }
}
@media (max-width: 1200px) {
  .box{
    width: 90%;
  }
  .technology_container_content > img{
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>